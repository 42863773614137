import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Template = ({ data }) => {
  const { markdownRemark: post } = data // data.markdownRemark holds your post data

  return (
    <Layout>
      <SEO title={`${post.frontmatter.title}  | Savage Outdoor & Defense`} />

      <div class="">
        <div class="bg-gray-50 sm:py-16 sm:px-6 ">
          <div class="p-8 bg-gray-800">
            <div class="max-w-full mx-auto">
              <div class="flex justify-center items-center content-center text-center">
                <div class="text-center">
                  <h2 class="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                    {post.frontmatter.heading}
                  </h2>
                  <p class="font-bold leading-7 text-white">
                    {post.frontmatter.subheading}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="max-w-3xl md:max-w-4xl sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
              <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`
export default Template
